@import '../../../../../styles/shared';

$border: 1px solid #eee;

.nav {
  @include mq($until: tablet) {
    display: none;
  }

  ul {
    display: flex;
    background: rgba(255, 255, 255, 0.4);
    border: $border;
    border-radius: 48px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }

  li {
    &:not(:last-child) {
      border-right: $border;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    padding: 0 15px;
    font-family: $font-family-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
    color: #000;
    background: rgba(255, 255, 255, 0.4);

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    @include mq($from: tablet) {
      min-height: 40px;
      padding: 0 24px;
      font-size: 16px;
    }
  }

  :global(.active) {
    &.anchor {
      background: #e1e0e0;
      pointer-events: none;
    }
  }
}

.select {
  position: relative;

  select {
    min-height: 32px;
    padding: 0 40px 0 20px;
    font-family: $font-family-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    background: rgba(255, 255, 255, 0.4);
    border: $border;
    border-radius: 48px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
    appearance: none;
  }

  @include mq($from: tablet) {
    display: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 15px;
    display: block;
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-size: 22px;
    color: #000;
    pointer-events: none;
  }
}
